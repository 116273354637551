'use client';

import { useEffect } from 'react'
import { MantineProvider, createTheme } from '@mantine/core';
import NavigationBar from "./Navbar";
import { ModalsProvider } from "@mantine/modals";
import Footer from "./Footer";
import { useAtom, useSetAtom } from 'jotai'
import * as atoms from '@/config/store/globalStore'
import { COOKIE_ITEMS, getCookieItem } from '@/utils/cookieControl'
import { API_ENDPOINT } from '@/config/endpoint/endpoint'
import dynamic from 'next/dynamic';
import AOS from 'aos'
import 'aos/dist/aos.css';
import mixpanel from 'mixpanel-browser';
import MobileMenu from './MobileMenu';
import { mixpanelSetUser } from '@/utils/mixpanel';
import Maintanance from '@/components/Maintanance';

const Toaster = dynamic(() =>
  import('react-hot-toast').then((toaster) => toaster.Toaster)
);

const theme = createTheme({
  defaultRadius: "0.5rem",
});

mixpanel.init('124f0686fc83c497f94548abbffafc64', {
  debug: true, 
  track_pageview: true,
  persistence: 'localStorage'
});


export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const [ userInfo, setUserinfo ] = useAtom(atoms.userInfo);
  const setSignedIn = useSetAtom(atoms.signIn);

  useEffect(() => {
    checkUserInfo()
    AOS.init({ once: true });
  }, []);
  
  async function checkUserInfo() {
    const signIn = getCookieItem(COOKIE_ITEMS.signIn)

    try {
      if (signIn === 'true') {
        const user = await API_ENDPOINT.account.checkUser();
        const { user_id, name, tier, credit } = user
        setSignedIn(true)
        setUserinfo({ user_id, name, tier, credit })
        mixpanelSetUser(userInfo)
      } else {
        setSignedIn(false)
      }
    } catch {
      setSignedIn(false)
      await API_ENDPOINT.account.logout()
    }
  }


  // 에러페이지
  // return (
//     <MantineProvider theme={theme}>
//       <ModalsProvider>
//         <Maintanance/>
//       </ModalsProvider>
//     </MantineProvider>
  // )


  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
        <Toaster
          toastOptions={{ duration: 2000 }}
          containerStyle={{ fontSize: 14 }}
        />
        <NavigationBar/>
        {children}
        <Footer/>
        {/* <MobileMenu/> */}
      </ModalsProvider>
    </MantineProvider>
  );
}