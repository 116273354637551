import { atom } from "jotai";

export const signIn = atom<boolean | null>(null)

export interface IUserInfo {
  user_id: string,
  name: string,
  tier: string,
  credit: string
}

export const userInfo = atom<IUserInfo>({
  user_id: '',
  name: '',
  tier: '',
  credit: ''
})
