import Emoji, { EmojiStyle } from "@/components/Emoji";
import Toast from "@/components/Toast";
import { API_ENDPOINT } from "@/config/endpoint/endpoint";
import themeVariables from "@/config/theme/themeVariable";
import { Affix, Button, Input, Modal, Textarea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { useRef } from "react";
import * as atoms from '@/config/store/globalStore'
import { useRouter } from "next/navigation";

export const FeatureRequestModal = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isSignIn = useAtomValue(atoms.signIn)
  const { push } = useRouter();

  const onClickSubmitButton = async () => {
    const content = inputRef.current?.value as string
    if (!content) {
      Toast.info('문의하실 내용을 채워주세요')
      return
    }

    API_ENDPOINT.inquery.sendFeatureRequestInquery(content)
    Toast.success('제출완료! 빠른 시일내에 준비해드릴게요')
    close()
  }


  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered
        radius={'lg'}
        padding={24}
        classNames={{ content: 'scrollbar-hide'}}
      >
        <div className="text-base text-gray-700 font-bold tracking-tight" data-autofocus>
          기능 요청
        </div>
        <div className="text-sm mt-1.5 mb-5 text-gray-600">원하시는 기능 없나요? 말씀해주시면 최대한 빨리 제공해드릴게요</div>
        <Textarea
          minRows={3}
          maxRows={5}
          autosize
          ref={inputRef}
          placeholder="원하시는 기능 종류/내용에 대해 적어주세요"
          classNames={themeVariables.input.filled}
        />
        <div className="flex gap-1 justify-end mt-5">
          <Button classNames={themeVariables.button.bordered} onClick={close}>
            닫기
          </Button>
          <Button classNames={themeVariables.button.dark} onClick={onClickSubmitButton}>
            제출하기
          </Button>
        </div>
      </Modal>
      <Button
        onClick={open}
        classNames={themeVariables.button.bordered}
      >
        기능 요청
      </Button>
    </>
  )
}